import React from "react";
import { Helmet } from 'react-helmet'
import "./App.scss"
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { LandingForm } from "../../components/landingForm"
import imgLogo from "./assets/img/logo-ucc.webp";
import imgBanner from "./assets/img/hero/gestion-de-proyectos.webp";
import imgCalendar from "./assets/img/icons/calendar.webp";
import imgPin from "./assets/img/icons/pin.webp";
import imgReloj from "./assets/img/icons/reloj.webp";
import imgDinero from "./assets/img/icons/dinero.webp";

const GestionDeProyectos = () => {
	return (
		<>
			<Helmet>
				<script>
				{`(function(w,d,s,l,i)
					{w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});
					var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
					j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-PLKH83F');`}
				</script>

				<noscript>
				{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PLKH83F"
						height="0" width="0" style="display:none;visibility:hidden"> 
					</iframe>`}
				</noscript>
			</Helmet>
			<Navbar sticky="top" bg="azul" className="navbar-landing">
				<Container
					style={{
						justifyContent: "start",
					}}
				>
					<Navbar.Brand>
						<img
							src={imgLogo}
							width="100%"
							height="100%"
							alt="Gestión de Proyectos"
						/>
					</Navbar.Brand>
				</Container>
			</Navbar>
			<main>
				<section
					className="hero hero-azul d-flex align-items-center"
					style={{ backgroundImage: `url(${imgBanner})` }}
				>
					<Container className="position-relative">
						<Row>
							<Col className="gap-2 d-flex flex-column">
								<div className="d-flex align-items-center gap-2 gap-md-3">
									<h3
										className="bg-white text-azul fw-light m-0"
										style={{ padding: '0.8rem' }}
									>
										Diplomatura{" "}
										<span className="fw-extrabold">
											Online
										</span>
									</h3>{" "}
									<h4 className="m-0 fw-light text-uppercase text-white">
										Inicio{" "}
										<span className="d-block fw-extrabold">
											3 de abril
										</span>
									</h4>
								</div>
								<h1>Gestión de Proyectos</h1>
								<h5 className="text-white text-uppercase fw-semibold">
									Primera Universidad Privada de Argentina
								</h5>
								<a
									href="#formulario"
									className="btn btn-hero btn-hero-azul shadow-sm d-flex align-self-center align-self-md-start smooth"
								>
									Inscríbete ahora
								</a>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="descripcion" className="bg-light-landing">
                <Container>
                        <Row>
                            <Col md={6} className="pt-5 pb-3 py-md-6">
								<h3 className="title text-azul">
									Consúltanos{" "}
									<span className="d-block">
										por este curso
									</span>
								</h3>
								<p className="p-style">
                                    La gestión estratégica de proyectos se encuentra hoy presente en todo tipo de empresas, instituciones y ámbitos de gestión, lo cual demanda profesionales capacitados en sus principios, buenas prácticas y herramientas para liderar emprendimientos, innovaciones y nuevas aplicaciones que faciliten el crecimiento y el desarrollo socio productivo de las organizaciones.
								</p>
								<p className="p-style">
                                    Este curso recorre los pilares esenciales de la disciplina de gestión de proyectos, sus principios y buenas prácticas, a fin de brindar al estudiante las herramientas necesarias para gestionar y liderar exitosamente sus proyectos.
								</p>
								<p className="p-style">
                                    Curso basado en el PMBOK 6ta. edición.
								</p>
								<p className="p-style">
                                    Diseñado y organizado por la Facultad de Ingeniería.
								</p>
								<p className="p-style">
                                    Se otorgará certificación digital oficial de la Universidad Católica de Córdoba.
								</p>

								<h5 className="p-style fw-bold">Docentes:</h5>
								<ul>
									<li>Dra. Conti, Maria Laura (Disertante)</li>
								</ul>
                                </Col>
                            
                            <Col md={6} className="pt-5 pb-6 py-md-6 px-md-4" id="formulario">  
								<div className='container px-4'>
									<LandingForm nomCurso="Gestión de Proyectos"/>
								</div>                            
                            </Col>
                        </Row>
					</Container>
				</section>
				<section className="position-relative">
					<a href="#resume" className="smooth icon-down">
						<FontAwesomeIcon icon={faArrowDown} style={{ fontSize: 40, width: 100 }} className="bi bi-arrow-down-short d-flex justify-content-center pt-2 text-azul"/>						
					</a>
					<Container>
						<Row id="resume" className="justify-content-center">
							<Col
								sm={11}
								className="bg-white rounded-4 shadow-sm mt-n4 mb-5"
								style={{ zIndex: 1 }}
							>
								<Row className="row-cols-2 row-cols-md-4 py-2 py-md-0">
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgCalendar}
											alt="Fecha de inicio"
										/>
										<div>
											<span className="d-block fw-medium">
												3 de Abril
											</span>
										</div>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgPin} alt="Ubicación" />
										<span className="text-capitalize fw-medium">
											- 100% Online <br />- Campus Virtual
											UCC
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img
											src={imgReloj}
											alt="Duración del diplomado"
										/>
										<span className="text-capitalize fw-medium">
											3 meses
											<br />
											70 horas totales.
										</span>
									</Col>
									<Col className="my-auto py-3 py-md-4 d-flex flex-column flex-md-row gap-2 gap-md-3 justify-content-center align-items-center">
										<img src={imgDinero} alt="Precio" />
										<span className="text-capitalize fw-medium">
											Público en general <br />
											USD 226
										</span>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="info">
					<Container className=" pt-4 pb-5 pb-md-6">
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									Objetivos del curso
								</h3>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col md={6}>
								{" "}
								<p className="p-style">
                                    Desarrollar las habilidades necesarias para identificar, analizar e implementar las mejores prácticas y estándares para la gestión de proyectos.
								</p>
								<p className="p-style">
                                    Reconocer y aplicar herramientas y técnicas para planificar y ejecutar el alcance, el cronograma, los costos, los riesgos, la calidad y las comunicaciones de un proyecto.
								</p>
							</Col>
							<Col md={6}>
								<p className="p-style">
                                    Identificar y seleccionar recursos, aplicaciones y sistemas para el monitoreo y la gestión de la información de un proyecto.
								</p>
								<p className="p-style">
                                    Indagar en las habilidades técnicas, personales e interpersonales necesarias para el ejercicio del liderazgo de un proyecto.
								</p>
							</Col>
						</Row>
						<Row className="mb-4">
							<Col className="text-center">
								<h3 className="title text-azul">
									¿A quién está dirigido?
								</h3>
							</Col>
						</Row>
						<Row>
							<Col md={6}>
								{" "}
								<p className="p-style">
                                    Estudiantes y profesionales de todo tipo de industrias, sectores y disciplinas que deseen adquirir herramientas para gestionar proyectos de manera eficiente.
								</p>
								<p className="p-style">
                                    Líderes e integrantes de oficinas de proyectos que busquen sustento teórico y práctico para su tarea cotidiana.
								</p>
							</Col>
							<Col md={6}>
								{" "}
								<p className="p-style">
                                    Profesionales que ocupen roles de mandos medios o gerencias, interesados en formarse como buenos gestores de proyectos.
								</p>
								<p className="p-style">
                                    Empresarios y emprendedores que necesiten desarrollar competencias para liderar sus proyectos profesionales.
								</p>
								<p className="p-style">
                                    No se requieren conocimientos previos específicos.
								</p>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="programa" className="bg-light-landing py-5 py-md-6">
					<Container>
						<Row>
							<Col className="text-center">
								<h3 class="title text-azul mb-3">Contenidos</h3>
							</Col>
						</Row>
						<Row>
							<Col>
								<Accordion>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="0"
										>
											Módulo 1
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="0">
											<Card.Body>
                                                Fundamentos de la gestión de proyectos. Metodologías de gestión tradicional y ágil.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="1"
										>
											Módulo 2
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="1">
											<Card.Body>
                                                Inicio y planificación de un proyecto.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="2"
										>
											Módulo 3
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="2">
											<Card.Body>
                                                Gestión del alcance, el cronograma y los costos de un proyecto.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="3"
										>
											Módulo 4
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="3">
											<Card.Body>
                                                Gestión de la calidad, los riesgos, los interesados y las comunicaciones de un proyecto.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="4"
										>
											Módulo 5
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="4">
											<Card.Body>
                                                Gestión de los RRHH. Liderazgo de un proyecto.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
									<Card>
										<Accordion.Toggle
											as={Card.Header}
											eventKey="5"
										>
											Módulo 6
										</Accordion.Toggle>
										<Accordion.Collapse eventKey="5">
											<Card.Body>
                                                Gestión del conocimiento. Herramientas para la gestión de proyectos.
											</Card.Body>
										</Accordion.Collapse>
									</Card>
								</Accordion>
							</Col>
						</Row>
					</Container>
				</section>
			</main>
            <footer class="bg-azul">
                <Container className="py-5">
                    <Row>
                        <Col className="text-center">
                            <h2>Certificación Oficial de la Universidad Católica de Córdoba</h2>
                        </Col>
                    </Row>

                </Container>

    </footer>
		</>
	);
};

export default GestionDeProyectos;
